import React from "react";
import { Link } from "gatsby";

const BrandSection = ({
  className,
  logo,
  text,
  bigTextClass,
  imgMove,
  mobileImage,
  mobileImageWebp,
  image,
  imageWebp,
  link,
  arrowDirection,
}) => {
  return (
    <section className={className ? `brand ` + className : `brand`} id="second">
      <div className="brand__wrapper">
        <div className="brand__text-wrapper">
          <div className="brand__logo">
            <img src={logo} alt="AM.PM" />
          </div>
          <h2
            className={bigTextClass ? `big-text ` + bigTextClass : `big-text`}
          >
            {text}
          </h2>
          <Link
            to={link}
            className={
              arrowDirection
                ? `arrow-down brand__arrow ` + arrowDirection
                : `arrow-down brand__arrow`
            }
          >
            <img src={imgMove} alt="AM.PM" className="brand__link-wrapper" />
          </Link>
        </div>
        <div className="brand__image-wrapper image-wrapper">
          <picture>
            <source
              type="image/webp"
              media="(max-width: 1024px)"
              srcSet={mobileImageWebp}
            />
            <source media="(max-width: 1024px)" srcSet={mobileImage} />
            <source type="image/webp" srcSet={imageWebp} />
            <img src={image} alt="Бренд" />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default BrandSection;
